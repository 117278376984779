<script setup></script>

<template>
  <div class="mt-10 flex w-full justify-center">
    <h1
      class="text-center text-4xl font-semibold uppercase tracking-wide text-black text-shadow-custom md:text-7xl">
      Connect Collaborate Create
    </h1>
  </div>
  <section class="flex flex-col justify-center pb-10">
    <div class="mx-auto mt-10 w-2/3">
      <p class="text-center font-semibold uppercase">
        At Spicy Gigs, we believe in pushing boundaries and embracing the unconventional. Our
        ​platform is designed for creators, companies, and crews seeking jobs that are a bit ​edgier
        and spicier than the norm. If you're looking for work that stands out and makes a
        ​statement, you've come to the right place.
      </p>
    </div>
    <div class="mt-10 flex w-full gap-6 lg:flex-row">
      <div class="mx-auto flex w-11/12 flex-col gap-4 lg:flex-row">
        <div class="flex-1">
          <img src="@images/hero1.webp" alt="" class="w-full border border-black object-contain" />
        </div>
        <div class="flex-1">
          <img src="@images/hero2.webp" alt="" class="w-full border border-black object-contain" />
        </div>
      </div>
    </div>
  </section>
</template>

<style>
  .text-shadow-custom {
    text-shadow: 2px 1px 1px rgb(7, 238, 207);
    opacity: 0.7;
  }
</style>
