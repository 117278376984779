<script setup></script>

<template>
  <div class="mt-10">
    <h2 class="text-2xl tracking-wider text-center uppercase text-shadow-custom">
      Enhanced Security Features
    </h2>
  </div>
  <div class="w-1/4 mx-auto mt-10">
    <img src="@images/home-image-3.svg" alt="" class="mx-auto w-28" />
  </div>
  <section class="flex flex-col gap-5 pb-10 mt-10 text-center uppercase">
    <p class="text-2xl font-semibold uppercase">
      At Spicy Gigs, we prioritize your safety and security. To ensure a trustworthy and
      professional ​environment, we offer robust security measures for all members.
    </p>
    <div class="text-base">
      <p>
        All members must undergo age verification to join our platform, ensuring a safe and
        appropriate community for creative professionals.
      </p>
      <p>For added security, we implement a two-factor authentication system.</p>
      <p>
        With these security features in place, you can confidently connect, collaborate, and grow
        your career on Spicy Gigs.
      </p>
    </div>
  </section>
</template>
