<!-- FadeInSection.vue -->
<template>
  <div ref="section" class="fade-in-section">
    <slot></slot>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';

  const section = ref(null);

  onMounted(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(section.value);
  });
</script>

<style scoped>
  .fade-in-section {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 1s ease-out,
      transform 1s ease-out;
  }

  .fade-in-section.visible {
    opacity: 1;
    transform: translateY(0);
  }
</style>
