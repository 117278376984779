<script setup></script>

<template>
  <div class="mt-10">
    <h2 class="text-2xl tracking-wider text-center uppercase text-shadow-custom">
      Effortless Gig Offers with Our Innovative Features
    </h2>
  </div>
  <div class="w-1/2 mx-auto mt-10">
    <img src="@images/home-image-2.webp" alt="" class="w-full" />
  </div>
  <section class="flex flex-col gap-5 pb-10 mt-10 text-center uppercase">
    <p>How It Works:</p>
    <p>
      Open up a gig offer and input all the important information, including gig details, dates, and
      compensation.
    </p>
    <p>Once completed, send the offer to the desired member with just a click.</p>
    <p>
      The receiving member can look through the offer, check all the details, and make a decision
      whether to accept or decline the offer.
    </p>
    <p>
      If the offer is accepted, our custom chat feature is automatically engaged, allowing both
      parties to discuss and plan the gig in detail.
    </p>
    <p>
      All communication and gig offers are saved, allowing you to go back at any time and check all
      the details of any gig you've ever done on the site.
    </p>
  </section>
</template>
