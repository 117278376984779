<script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { useToast } from 'primevue/usetoast';

  const showNotification = ref(true);
  const email = ref('');
  const notificationVisible = ref(false);

  const toast = useToast();

  const closeNotification = () => {
    notificationVisible.value = false;
    setTimeout(() => {
      showNotification.value = false;
    }, 300); // Delay to allow sliding effect to finish
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!email.value || !email.value.includes('@')) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid email address.',
      });
      return;
    }

    try {
      await axios.post('/potential-emails', { email: email.value });

      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Thank you for subscribing!',
        life: 5000,
        style: { opacity: 0.9 },
      });
      closeNotification();
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an error. Please try again.',
        life: 5000,
        style: { opacity: 0.9 },
      });
    }
  };

  // Show notification after a delay
  onMounted(() => {
    setTimeout(() => {
      notificationVisible.value = true;
    }, 5000); // Show after 5 seconds
  });
</script>

<template>
  <transition name="slide-fade">
    <div
      v-if="notificationVisible"
      class="fixed bottom-0 right-0 z-50 mb-4 mr-4 w-80 transform rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
      <button
        @click="closeNotification"
        class="absolute right-2 top-2 text-gray-600 hover:text-gray-900">
        <i class="fas fa-times"></i>
      </button>
      <p class="mb-2 font-bold">Stay Connected and Up to Date with Us</p>
      <p class="mb-4">Subscribe to our newsletter to get the latest updates and offers.</p>
      <form @submit="submitForm" class="flex flex-col">
        <input
          v-model="email"
          type="email"
          placeholder="Enter your email"
          class="mb-2 rounded border border-gray-300 px-4 py-2" />
        <button
          type="submit"
          class="w-full rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
          Join us!
        </button>
      </form>
    </div>
  </transition>
</template>

<style scoped>
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition:
      opacity 0.5s ease,
      transform 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-fade-leave {
    opacity: 0;
    transform: translateX(100%);
  }
</style>
