<script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { useToast } from 'primevue/usetoast';
  import Notification from '@/Components/Notification.vue';
  import NewNavbar from '@/Components/Navs/NewNavbar.vue';
  import NewHero from '@/Components/NewHero.vue';
  import NewMiddleSection from '@/Components/NewMiddleSection.vue';
  import NewMiddleSection2 from '@/Components/NewMiddleSection2.vue';
  import NewMiddleSection3 from '@/Components/NewMiddleSection3.vue';
  import FadeInSection from '@/Components/FadeInSection.vue';
  import Footer from '@/Components/Footer.vue';
  import EmailSubscriptionForm from '@/Components/EmailSubscriptionForm.vue';

  const showJoinUsButton = ref(true);
  const showNotification = ref(false);
  const showEmailForm = ref(false);

  const email = ref('');
  const notificationVisible = ref(false);
  const toast = useToast();

  const handleJoinUsClick = () => {
    showEmailForm.value = true;
    // showJoinUsButton.value = false;
  };

  const showEmailSubscriptionForm = () => {
    showEmailForm.value = true;
  };

  const closeNotification = () => {
    showEmailForm.value = false;
  };

  // Submit form logic
  const submitForm = async (event) => {
    event.preventDefault();
    if (!email.value || !email.value.includes('@')) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid email address.',
      });
      return;
    }

    try {
      await axios.post('/potential-emails', { email: email.value });

      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Thank you for subscribing!',
        life: 5000,
        style: { opacity: 0.9 },
      });
      closeNotification();
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an error. Please try again.',
        life: 5000,
        style: { opacity: 0.9 },
      });
    }
  };
</script>

<template>
  <Toast />
  <div class="min-h-screen bg-gray-50">
    <Notification v-if="showNotification" @close="handleNotificationClose" />
    <NewNavbar />
    <FadeInSection>
      <NewHero />
    </FadeInSection>
    <hr class="border-purple-600" />
    <FadeInSection>
      <NewMiddleSection />
    </FadeInSection>
    <hr class="border-purple-600" />
    <FadeInSection>
      <NewMiddleSection2 />
    </FadeInSection>
    <FadeInSection>
      <NewMiddleSection3 />
    </FadeInSection>

    <footer class="h-6 bg-black">
      <Footer />
    </footer>

    <!-- Join Us Button -->
    <transition name="fade">
      <button
        v-show="showJoinUsButton"
        @click="showEmailSubscriptionForm"
        class="fixed bottom-4 right-4 rounded bg-orange-500 p-2 text-white shadow-lg">
        <i class="fas fa-users"></i>
        Join Us
      </button>
    </transition>

    <!-- Email Subscription Form -->
    <transition name="slide-fade">
      <div
        v-if="showEmailForm"
        class="fixed bottom-0 right-0 z-50 mb-4 mr-4 w-80 transform rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
        <button
          @click="closeNotification"
          class="absolute right-2 top-2 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        <p class="mb-2 font-bold">Stay Connected and Up to Date with Us</p>
        <p class="mb-4">Subscribe to our newsletter to get the latest updates and offers.</p>
        <form @submit="submitForm" class="flex flex-col">
          <input
            v-model="email"
            type="email"
            placeholder="Enter your email"
            class="mb-2 rounded border border-gray-300 px-4 py-2" />
          <button
            type="submit"
            class="w-full rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
            Join us!
          </button>
        </form>
      </div>
    </transition>

    <!-- TODO: MAKE THIS WORK -->
    <!-- <EmailSubscriptionForm
      v-if="showEmailForm"
      :email="email"
      @submit="submitForm"
      @close="closeNotification" /> -->
  </div>
</template>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition:
      opacity 0.5s ease,
      transform 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
</style>
