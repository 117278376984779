<script setup></script>

<template>
  <div class="mt-10">
    <h2 class="text-2xl tracking-wider text-center uppercase text-shadow-custom">
      Your All-in-One Profile Page
    </h2>
  </div>
  <div class="w-1/2 mx-auto mt-10">
    <img src="@images/home-image-1.webp" alt="" class="w-full" />
  </div>
  <section class="flex flex-col gap-5 pb-10 mt-10 text-center uppercase">
    <p>Profile Page Features:</p>
    <p>Upload and display your best photos, highlighting your unique style and versatility.</p>
    <p>
      Keep track of your gigs and availability with an integrated calendar, making it easy for
      others to see when you're available.
    </p>
    <p>
      Showcase your experience with a detailed job history, giving potential clients and
      collaborators a clear view of your past work.
    </p>
    <p>All your communications in one place, allowing you to manage conversations with ease.</p>
    <p>
      Share your profile with potential clients or collaborators using simple, user-friendly links.
    </p>
  </section>
</template>

<style>
  .text-shadow-custom {
    text-shadow: 1px 1px 1px rgb(7, 238, 207);
    opacity: 0.7;
  }
</style>
